// import "./About.css";

import { useContext, useState } from "react";

import Navbar from "./About/Navbar";
import { Outlet } from "react-router-dom";

import CookieContext from "../contexts/CookieContext";


const About = () => {
  const { cookies, setJwtCookies, removeJwtCookies } = useContext(CookieContext);

  return (
    <>
      <div className="bd-callout bd-callout-info">
        <Navbar />
      </div>
      uuid:{cookies.accessTokenUuid}<br />
      <div className="bd-callout bd-callout-info">
      <Outlet />
      </div>
      <div className="ui raised very padded text container segment"
    style={{marginTop: '0px'}}
    >
    <h3 className="ui header">About</h3>
      <p>About</p>
    </div>
    </>
  );
};
export default About
