import axios from "axios";
import { useContext, useEffect, useState, FormEventHandler } from "react";
import { useForm } from 'react-hook-form';

// import { format } from "date-fns";
import CookieContext from "../contexts/CookieContext";
import AppContext from '../contexts/AppContext';

const baseURL = "https://api.btime.online/";
const pathRoulette = "roulette/";

const MyPage = () => {
  const { cookies, setJwtCookies, removeJwtCookies } = useContext(CookieContext);
  const { variableCheck } = useContext(AppContext);
  const [roulettes, setRoulettes] = useState([]);
  const [title, setTitle] = useState([]);
  const handleInputChange = (e) => {
    setTitle(e.value);
  }
  const {
    register,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm();

  const onSubmit = (e) => {
    // console.log(title)
    // alert(title);
    // variableCheck(e.target);
    // const { firstName } = e.target.firstName
    // alert(e);
    // alert(data.title);
    axios({
      method: 'post',
      url: baseURL + pathRoulette,
      // url: 'http://127.0.0.1:8000/roulette/',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${cookies.jwtAccess}`
      },
      data: {
        // title: 'Fred',
        title: 'ffffffffffffffff',
      }
    })
    .then(function (response) {
      console.log(response)
    });
  }

  console.log(watch("example"))

  const axios_fetch = async () => {
    try {
      await axios
        .get(baseURL + pathRoulette,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${cookies.jwtAccess}`
            }
          }
        ).then((response) => {
        console.log(response)
        setRoulettes(response.data['results']);
      });
    } catch (error) {
      return;
    }
  };

  axios_fetch()

  console.log(roulettes)
  

  return (
    <>
      <p>{JSON.stringify(roulettes)}</p>
      <pre>{JSON.stringify(roulettes)}</pre>
      <div>
        {Array.isArray(roulettes) ?
          roulettes?.map((data, i) => (
            <div key={i}>
              <p>
                {i}:{data?.title}
                {data?.created}
              </p>
            </div>
          ))
          : 'だめです'
        }
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <select {...register("gender")}>
          <option value="female">female</option>
          <option value="male">male</option>
          <option value="other">other</option>
        </select>
        <input defaultValue='aaa' {...register("title", { required: true, maxLength: 20 })} />
        {errors.title && <span>This field is required</span>}
        <input {...register("lastName", { pattern: /^[A-Za-z]+$/i })} />
        <input type="number" {...register("age", { min: 18, max: 99 })} />
        <input type="submit" />
      </form>
    </>
  )
}
export default MyPage


