import { motion, useAnimationControls, animate, useMotionValue, motionValue, useTransform, } from "framer-motion";
import { useEffect, useState, } from "react";
import useSound from "use-sound";

import start from './sounds/inRaffle.mp3';
import game from './sounds/stop.mp3';

import styled from "@emotion/styled";

const StyledButton = styled.button`
  width: 150px;
  color: ${({ disabled }) => disabled ? '#ffffff' : '#cccccc'};
  background: ${({ disabled }) => disabled ? 'linear-gradient(180deg, #666666, #999999)' : 'linear-gradient(180deg, #100c08, #000099)'};
  ${({ disabled }) => disabled && `text-shadow: #cccccc 5px 5px 10px; box-shadow: #cccccc 5px 5px 10px;`}
`;

const ActD = () => {

  const [playStart] = useSound(start);
  const [playGame] = useSound(game);

  const controls = useAnimationControls();

  // 抽選数字
  const [ selectedNumber, setSelectedNumber ] = useState(0);
  // 抽選中数字（小数点以下あり）
  const [ inRaffleNumber, setInRaffleNumber ] = useState(useMotionValue(0));
  // 抽選中数字（小数点以下切り捨て）
  const [ inRaffleFloorNumber, setInRaffleFloorNumber ] = useState(useTransform(inRaffleNumber, Math.floor));
  const [ displayNumber, setDisplayNumber ] = useState(0);
  const [ buttonDisabled, setButtonDisabled ] = useState(false);

  // 抽選数字の最大値
  let maxNumber = 10;
  // ルーレット回転数
  let rouletteRoundCounter = 3;
  // 止まるまでの時間
  let stopTimer = 1;

  const rouletteText = [
    'あああああああああああ',
    'かかかかかかかかかかか',
    'さささささささささささ',
    'たたたたたたたたたたた',
    'ななななななななななな',
    'ははははははははははは',
    'ままままままままままま',
    'ややややややややややや',
    'ららららららららららら',
    'わわわわわわわわわわわ',
  ]

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const handleRouletteStart = async() => {
    setButtonDisabled(true);

    // 前回止まった位置をスタート位置とする
    await inRaffleNumber.set(selectedNumber % maxNumber);
    
    // 1～Xの数字をランダムでget
    await setSelectedNumber(randomNumberInRange(maxNumber * rouletteRoundCounter, maxNumber * (rouletteRoundCounter + 1) - 1));
  }

  const SoundCheck = async () => {
    if(inRaffleFloorNumber.get() + 1 < inRaffleNumber.get()){
      // 次の数字になったら、音を鳴らす
      await playStart();
      controls.start({
        backgroundColor: ['#000', '#00f', ],
        color: ['#ff0', '#fff',],
        outlineStyle: 'none',
        transition: {
          duration: 1.0,
        },
      });
      setDisplayNumber(parseInt(inRaffleFloorNumber.get() % maxNumber + 1));
    } else if( inRaffleNumber.get() != 0 && inRaffleNumber.get() === selectedNumber){
      // 抽選数字なら音を鳴らして文字色変更
      await playGame();
      controls.start({
        backgroundColor: ['#68cae7', '#000'],
        color: ['#68cae7', '#81e751'],
        outlineStyle: 'solid',
        outlineColor: ['#68cae7', '#00ff00'],
        transition: {
          duration: 0.5,
        },
      });
      await setDisplayNumber(parseInt(inRaffleFloorNumber.get() % maxNumber + 1));
      // 抽選が終ったため、ボタンを有効にする
      await setButtonDisabled(false);
    } else {
      console.log(inRaffleFloorNumber.get(), ":", inRaffleNumber.get())
    }
  }

  animate(inRaffleNumber, selectedNumber, {
    duration: 1,
    onUpdate: latest => [
      // デバッグ用コンソールログ
      // console.log('displayNumber:', displayNumber, ' selectedNumber:', selectedNumber, ' inRaffleFloorNumber:', inRaffleFloorNumber.get(), ' inRaffleNumber:', inRaffleNumber.get(),
      // ' buttonDisabled:', buttonDisabled,
      console.log('displayNumber:', displayNumber, ' inRaffleFloorNumber:', inRaffleFloorNumber.get(), ' inRaffleNumber:', inRaffleNumber.get(),
      ' buttonDisabled:', buttonDisabled,
      ' latest:', latest ),
      SoundCheck(),
    ]
  });

  useEffect(() => {
    controls.start({
      backgroundColor: ['#66ff00', '#000', '#00ff6a', '#000', '#ff9100', '#000','#ac0a17', '#000', '#000', '#000', '#00f', '#000',],
      color: '#fff',
      transition: {
        duration: 3.0,
      },
    });
    setDisplayNumber(1)
  }, []);

  return (
    <>
      {/* 全リスト */}
      <div
      style={{padding: '10px', margin: '0 auto', width: '330px',
        background: 'linear-gradient(180deg, #000000, #000000', border: '1px solid #cccccc', color: '#cccccc',
        fontFamily: '"Noto Sans Math", sans-serif', fontWeight: 500, textAlign: 'left' }}>
        <div>
          {rouletteText.map((item, index) => {
            console.log('index:', index, ' displayNumber:', displayNumber)
            if(index < maxNumber) {
              // 一致している項目は、緑か黄色で表示
              if(index === displayNumber - 1){
                // 停止数字だった場合は緑、そうでなければ黄色
                if(selectedNumber === inRaffleNumber.get() && selectedNumber !== 0) {
                  return <div key={index} style={{ backgroundColor: '#66ff33', color: '#000000', }}>{index+1}:{item}</div>;
                } else {
                  return <div key={index} style={{ backgroundColor: '#ffff00', color: '#000000', }}>{index+1}:{item}</div>;
                }
              } else {
                return <div key={index}>{index+1}:{item}</div>;
              }
            }
          })}
        </div>
      </div>
      <div>
        <StyledButton disabled={buttonDisabled} onClick={() => handleRouletteStart()}>
          抽選
        </StyledButton>
      </div>
    </>
  )
}
export default ActD
