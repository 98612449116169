import "./App.css";
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import Layout, { layoutLoader } from "./components/Layout";
import Home from "./components/Home";
import HomeRoot from "./components/Home/Root";
import HomeAbc from "./components/Home/Abc";
import HomeDef from "./components/Home/Def";
import About from "./components/About";
import AboutRoot from "./components/About/Root";
import AboutAbc from "./components/About/Abc";
import AboutDef from "./components/About/Def";
import Roulette from "./components/Roulette";
import RouletteRoot from "./components/Roulette/Root";
import RouletteAbc from "./components/Roulette/Abc";
import RouletteDef from "./components/Roulette/Def";
import RouletteSpin from "./components/Roulette/Spin";
import RouletteActA from "./components/Roulette/ActA";
import RouletteActB from "./components/Roulette/ActB";
import RouletteActC from "./components/Roulette/ActC";
import RouletteActD from "./components/Roulette/ActD";
import RouletteActE from "./components/Roulette/ActE";
import RouletteActF from "./components/Roulette/ActF";
import RouletteActG from "./components/Roulette/ActG";
import Parent from "./components/Parent";
import ParentRoot from "./components/Parent/Root";
import ParentAbc from "./components/Parent/Abc";
import ParentDef from "./components/Parent/Def";
import MyPage from "./components/MyPage";

import { CookieContextProvider } from "./contexts/CookieContext";
import { AppContextProvider } from "./contexts/AppContext";
import { NavbarContextProvider } from "./contexts/NavbarContext";
import { ExpTimeContextProvider } from "./contexts/ExpTimeContext";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <h2>ErrorPage</h2>,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "home",
          element: <Home />,
          children: [
            {
              index: true,
              element: <HomeRoot />,
            },
            {
              path: "abc",
              element: <HomeAbc />,
            },
            {
              path: "def",
              element: <HomeDef />,
            },
          ],
        },
        {
          path: "about",
          element: <About />,
          children: [
            {
              index: true,
              element: <AboutRoot />,
            },
            {
              path: "abc",
              element: <AboutAbc />,
            },
            {
              path: "def",
              element: <AboutDef />,
            },
          ],
        },
        {
          path: "roulette",
          element: <Roulette />,
          children: [
            {
              index: true,
              element: <RouletteRoot />,
            },
            {
              path: "abc",
              element: <RouletteAbc />,
            },
            {
              path: "def",
              element: <RouletteDef />,
            },
            {
              path: "spin",
              element: <RouletteSpin />,
            },
            {
              path: "actA",
              element: <RouletteActA />,
            },
            {
              path: "actB",
              element: <RouletteActB />,
            },
            {
              path: "actC",
              element: <RouletteActC />,
            },
            {
              path: "actD",
              element: <RouletteActD />,
            },
            {
              path: "actE",
              element: <RouletteActE />,
            },
            {
              path: "actF",
              element: <RouletteActF />,
            },
            {
              path: "actG",
              element: <RouletteActG />,
            },
          ],
        },
        {
          path: "mypage",
          element: <MyPage />,
        },
        {
          path: "about",
          element: <About />,
        },
        {
          path: "parent",
          element: <Parent />,
          children: [
            {
              index: true,
              element: <ParentRoot />,
            },
            {
              path: "abc",
              element: <ParentAbc />,
            },
            {
              path: "def",
              element: <ParentDef />,
            },
          ],
        },
      ],
    },
  ]);

  return (
    <div className="App">
      <CookieContextProvider>
        <AppContextProvider>
          <NavbarContextProvider>
            <ExpTimeContextProvider>
              <RouterProvider router={router} />
            </ExpTimeContextProvider>
          </NavbarContextProvider>
        </AppContextProvider>
      </CookieContextProvider>
    </div>
  );
}

export default App;
