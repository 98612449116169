import "./Footer.css";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { format, addMilliseconds } from "date-fns";
// import { TZDate } from "@date-fns/tz";

import CookieContext from "../contexts/CookieContext";

// const baseURL = "http://127.0.0.1:8000/";
const baseURL = "https://api.btime.online/";
const pathResponsetime = "httprequest/responsetime/";

const Footer = () => {
  const { cookies } = useContext(CookieContext);
  const interval = 5000;
  const [id, setId] = useState("");
  const [ip, setIp] = useState("");
  const [clock, setClock] = useState("00:00:00");

  // const [authorization, setAuthorization] = useState();

  // 0.2秒毎に更新
  const [intervalTime, setIntervalTime] = useState(200);
  // intervalAccessTime = interval + responseTimeで接続の度に更新
  const [intervalAccessTime, setIntervalAccessTime] = useState(interval);
  // nextAccessTimeは次回アクセスの参考表示用
  const [nextAccessTime, setNextAccessTime] = useState(
    format(addMilliseconds(Date.now(), interval), "HH:mm:ss")
  );

  const [responseTime, setResponseTime] = useState(0);

  // 前回記録保持用
  const [lastRequestDate, setLastRequestDate] = useState("1999-12-31 23:59:59");
  const [lastResponseDate, setLastResponseDate] = useState("1999-12-31 23:59:59");

  const updateClock = async () => {
    // 出力フォーマット
    // const curTime = format(Date.now(), "HH:mm:ss.S");
    const curTime = format(Date.now(), "HH:mm:ss");
    setClock(curTime);
  };

  const axios_fetch = async () => {
    const start = Date.now();
    try {
      if (cookies.jwtAccess) {
        // jwtがある場合、headers（認証情報）有り
        await axios({
          method: "get",
          url: baseURL + pathResponsetime,
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${cookies.jwtAccess}`,
          },
          params: {
            // last_request_time: "1999-01-01 01:01:01",
            // last_response_time: "2000-01-01 01:01:01",
            last_request_date: lastRequestDate,
            last_response_date: lastResponseDate,
            response_time: responseTime,
          },
        }).then(async (response) => {
          await console.log(response);
          await setId(response.data[0].id);
          await setIp(response.data[0].ip_addr);
        });
      } else {
        // jwtがない場合、headers（認証情報）無し
        await axios({
          method: "get",
          url: baseURL + pathResponsetime,
          params: {
            // last_request_time: "2009-12-31 23:59:59",
            // last_response_time: "2010-12-31 23:59:59",
            last_request_date: lastRequestDate,
            last_response_date: lastResponseDate,
            response_time: responseTime,
          },
        }).then(async (response) => {
          await console.log(response);
          await setId(response.data[0].id);
          await setIp(response.data[0].ip_addr);
        });
      }
    } catch (error) {
      return;
    }
    const finish = Date.now();

    // 次回リクエスト時に送信するために値を格納
    await setLastRequestDate(format(start, "yyyy-MM-dd HH:mm:ss.SSS"));
    await setLastResponseDate(format(finish, "yyyy-MM-dd HH:mm:ss.SSS"));

    // await setLastRequestDate();
    // formatInTimeZone(start, "Asia/Tokyo", "yyyy-MM-dd HH:mm:ss.SSS xxx")
    // TZDate(start, "Asia/Tokyo", "yyyy-MM-dd HH:mm:ss.SSS xxx")
    // await setLastResponseDate();
    // formatInTimeZone(finish, "Asia/Tokyo", "yyyy-MM-dd HH:mm:ss.SSS xxx")
    // TZDate(finish, "Asia/Tokyo", "yyyy-MM-dd HH:mm:ss.SSS xxx")
    await setResponseTime(finish - start);
    await setIntervalAccessTime(interval + responseTime);

    await setNextAccessTime(format(addMilliseconds(Date.now(), interval + responseTime), "HH:mm:ss"));
    // TZDate(addMilliseconds(Date.now(), interval + responseTime), "HH:mm:ss")
  };

  // 時刻表示用（レスポンスタイム考慮無し）
  useEffect(() => {
    const timer = setInterval(
      () => {
        updateClock();
      },
      intervalTime,
      [ip]
    );
    return () => {
      clearInterval(timer);
    };
  }, [clock]);

  // 更新時間表示用（レスポンスタイム考慮有り）
  useEffect(() => {
    const timer = setInterval(
      () => {
        axios_fetch();
      },
      intervalAccessTime,
      []
    );
    return () => {
      clearInterval(timer);
    };
  }, [nextAccessTime]);

  // 初回アクセス時に一度だけ実行（2回目以降は「nextAccessTime」更新時に実行）
  useEffect(() => {
    axios_fetch();
  }, []);

  return (
    <>
      <div className="timer" style={{ margin: "5px" }}>
        <div>
          clock:{clock}({intervalTime}ms)
          <br />
          nextAccessTime:{nextAccessTime}
          <br />
          今回のレスポンスタイム：{responseTime}ms&nbsp;(serial {id ? id : "X"})
          <br />
          次回アクセスまでのインターバル：
          {Math.round(intervalAccessTime / 100) / 10}秒（{intervalAccessTime}
          ms）
          <br />
          <h2>
            {/* {count.map((counter, index) => {
              return (
                <>
                  <li key={counter.index}>
                    【{counter.count}】IP:{counter.ip_addr}【
                    {format(counter.created_date, "HH:mm:ss")}】
                  </li>
                </>
              );
            })} */}
          </h2>
        </div>
      </div>
      <div className="bd-callout bd-callout-warning" style={{ margin: "5px" }}>
        <p>
          【認証情報】
          {/* ログインしていたらユーザ情報表示 */}
          <span className="item">
            ユーザ名:
            {cookies.accessTokenUsername
              ? cookies.accessTokenUsername
              : "guest"}
          </span>
          <span className="item">
            有効期限:
            {cookies.accessTokenExpTime
              ? cookies.accessTokenExpTime
              : "XXXX/XX/XX XX:XX:XX"}
          </span>
          <span className="item">
            uuid:
            {cookies.accessTokenUuid
              ? cookies.accessTokenUuid
              : "XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"}
          </span>
        </p>
        <p>
          【IP】
          <span className="item">
            {ip ? ip : "xxx.xxx.xxx.xxx"}
          </span>
        </p>
      </div>
    </>
  );
};
export default Footer;
