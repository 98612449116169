import { NavLink } from 'react-router-dom'
import { useContext, useState } from "react";

import styled from "@emotion/styled";
import NavbarContext from '../../contexts/NavbarContext';

const StyledButton = styled.button`
  color: ${({ activeAbout }) => activeAbout ? '#66ff33' : 'lightgray'};
  background: ${({ activeAbout }) => activeAbout ? 'linear-gradient(180deg, #003300, #006633)' : 'linear-gradient(180deg, #100c08, #003300)'};
  ${({ activeAbout }) => activeAbout && `text-shadow: lightgray 5px 5px 10px; box-shadow: #66ff33 5px 5px 10px;`}
`;
// const StyledButton = styled.button`
//   color: ${({ active }) => active ? '#66ff33' : 'lightgray'};
//   background: ${({ active }) => active ? 'linear-gradient(180deg, #003300, #006633)' : 'linear-gradient(180deg, #100c08, #003300)'};
//   ${({ active }) => active && `text-shadow: lightgray 5px 5px 10px; box-shadow: #66ff33 5px 5px 10px;`}
// `;

const AboutNavbar = () => {

  const { navsAbout, navsJAbout, activeAbout, setActiveAbout, } = useContext(NavbarContext);

  return (
    <>
      {navsAbout.map((nav, index) => {
      // {navs.map((nav, index) => {
        return (
          <span key={index}>
            <NavLink to={nav}>
              <StyledButton key={nav}
                activeAbout={activeAbout === nav}
                onClick={() => setActiveAbout(nav)}
              >{navsJAbout[index]}</StyledButton>
            </NavLink>
          </span>
        )}
      )}
    </>
  )
}
export default AboutNavbar

