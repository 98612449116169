import { redirect, useLoaderData } from 'react-router';
import { useContext } from "react";

import Account from "./Account";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

import CookieContext from "../contexts/CookieContext";

export const layoutLoader = async () => {
  if (true) {
    console.log("88888888")
  }
  return (
    <>
    "ffffff"
    </>
  )



  // const user = await checkAuthStatus(); //認証情報を取得
  // if (!user) return redirect("/login") //認証されていなかったらリダイレクト
  // return { user }
}


const Layout = () => {
  const { cookies, setJwtCookies, removeJwtCookies } = useContext(CookieContext);

  return (
    <>
      <div className="bd-callout bd-callout-info">
        uuid:{cookies.accessTokenUuid}<br />
        <Account />
      </div>
      <div className="bd-callout bd-callout-info">
        <Navbar />
      </div>
      <div className="bd-callout bd-callout-info">
      <Outlet />
      </div>
      <div className="bd-callout bd-callout-info">
        <Footer />
      </div>
    </>
  );
};
export default Layout;
