import { NavLink } from 'react-router-dom'
import { useContext, useState } from "react";

import styled from "@emotion/styled";
import NavbarContext from '../../contexts/NavbarContext';

const StyledButton = styled.button`
  color: ${({ active }) => active ? '#cccc00' : 'lightgray'};
  background: ${({ active }) => active ? 'linear-gradient(180deg, #663300, #996633)' : 'linear-gradient(180deg, #100c08, #663300)'};
  ${({ active }) => active && `text-shadow: lightgray 5px 5px 10px; box-shadow: #cccc00 5px 5px 10px;`}
`;

const ParentNavbar = () => {

  const { navsParent, navsJParent, activeParent, setActiveParent, } = useContext(NavbarContext);

  return (
    <>
      {navsParent.map((nav, index) => {
        return (
          <span key={index}>
            <NavLink to={nav}>
              <StyledButton key={nav}
                active={activeParent === nav}
                onClick={() => setActiveParent(nav)}
              >{navsJParent[index]}</StyledButton>
            </NavLink>
          </span>
        )}
      )}
    </>
  )
}
export default ParentNavbar

