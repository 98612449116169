// import "./Home.css";

import { useContext, useState } from "react";

import Navbar from "./Home/Navbar";
import { Outlet } from "react-router-dom";

import CookieContext from "../contexts/CookieContext";


const Home = () => {
  const { cookies, setJwtCookies, removeJwtCookies } = useContext(CookieContext);

  return (
    <>
      <div className="bd-callout bd-callout-info">
        <Navbar />
      </div>
      uuid:{cookies.accessTokenUuid}<br />
      <div className="bd-callout bd-callout-info">
      <Outlet />
      </div>
    </>
  );
};
export default Home
