import { NavLink } from 'react-router-dom'
import { useContext, useState } from "react";

import styled from "@emotion/styled";
import NavbarContext from '../../contexts/NavbarContext';

const StyledButton = styled.button`
  width: 100px;
  color: ${({ active }) => active ? '#cccccc' : 'lightgray'};
  background: ${({ active }) => active ? 'linear-gradient(180deg, #666666, #999999)' : 'linear-gradient(180deg, #100c08, #666666)'};
  ${({ active }) => active && `text-shadow: lightgray 5px 5px 10px; box-shadow: #cccccc 5px 5px 10px;`}
`;

const RouletteNavbar = () => {

  const { navsRoulette, navsJRoulette, activeRoulette, setActiveRoulette, } = useContext(NavbarContext);

  return (
    <>
      {navsRoulette.map((nav, index) => {
        return (
          <span key={index}>
            <NavLink to={nav}>
              <StyledButton key={nav}
                active={activeRoulette === nav}
                onClick={() => setActiveRoulette(nav)}
              >{navsJRoulette[index]}</StyledButton>
            </NavLink>
          </span>
        )}
      )}
    </>
  )
}
export default RouletteNavbar

