import { createContext, useReducer, useRef, useState, useEffect } from "react";
import { useCookies } from "react-cookie";

const AppContext = createContext();

const variableCheck = (variableName, variableValue) => {
  if (typeof variableValue === "object") {
    // string output
    console.log(
      "■[variableCheck][%s] %s:%s",
      typeof variableValue,
      variableName,
      JSON.stringify(variableValue)
    );

    //table output
    console.table(variableValue);
  } else {
    console.log(
      "■[variableCheck][%s] %s:%s",
      typeof variableValue,
      variableName,
      variableValue
    );
  }
};

let initialCount = 1;

export const AppContextProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies();

  // count（cookie）がNaNの場合は1、NaN以外の場合はcount（cookie）を数値型に変換してinitialCountに代入
  {
    // isNaN(cookies.get("count"))
    isNaN(cookies.count)
      ? (initialCount = 1)
      : (initialCount = Number(cookies.count));
  }

  // const [count, dispatchCount] = useReducer(countReducer, initialCount);
  const [apiUserHost, setApiHost] = useState("https://api.btime.online/");
  const [alertMessage, setAlertMessage] = useState("alert:-");

  const [syukujitsu] = useState();

  return (
    <AppContext.Provider
      value={{
        removeCookie,
        variableCheck,
        apiUserHost,
        alertMessage,
        syukujitsu,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
