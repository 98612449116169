import { motion, useAnimationControls, animate, useMotionValue, motionValue, useTransform, } from "framer-motion";
import { useEffect, useState, } from "react";
import useSound from "use-sound";

import start from './sounds/inRaffle.mp3';
import game from './sounds/stop.mp3';

const ActA = () => {

  const [playStart] = useSound(start);
  const [playGame] = useSound(game);

  const controls = useAnimationControls();
  const [ selectedNumber, setSelectedNumber ] = useState(0);
  const [ displayNumber, setDisplayNumber ] = useState(0);
  const [ buttonDisabled, setButtonDisabled ] = useState(false);

  // ルーレット回転数
  let rouletteRoundCounter = 3;
  // 止まるまでの時間
  let stopTimer = 2;
  
  // 抽選中数字（小数点以下あり）
  const inRaffleNumber = useMotionValue(0);
  // 抽選中数字（小数点以下切り捨て）
  const inRaffleFloorNumber = useTransform(inRaffleNumber, Math.floor);

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const handleRouletteStart = async() => {
    setButtonDisabled(true);

    // 0からスタート
    await inRaffleNumber.set(0);
    // 抽選数字（selectedNumber）が前回と同じ場合でもuseEffect[selectedNumber]を有効にするために値変更
    await setSelectedNumber(0);

    // 1～Xの数字をランダムでget
    await setSelectedNumber(randomNumberInRange(101, 201));
  }

  const SoundCheck = async () => {
    if(inRaffleFloorNumber.get() + 1 < inRaffleNumber.get()){
      await playStart();
      await controls.start({
        backgroundColor: ['#00f', '#000', ],
        color: ['#ff0', '#fff',],
        transition: {
          duration: 0.2,
        },
      });
    } else if( inRaffleNumber.get() != 0 && inRaffleNumber.get() === selectedNumber){
      await playGame();
      await controls.start({
        backgroundColor: ['#000', ],
        color: '#81e751',
        transition: {
          duration: 0.0,
        },
      });
      // 抽選が終ったため、ボタンを有効にする
      await setButtonDisabled(false);
    } else {
      console.log(inRaffleFloorNumber.get(), ":", inRaffleNumber.get())
    }
  }

  animate(inRaffleNumber, selectedNumber, {
    duration: 10,
    onUpdate: latest =>
      [console.log('displayNumber:', displayNumber, ' selectedNumber:', selectedNumber, ' inRaffleFloorNumber:', inRaffleFloorNumber.get(), ' inRaffleNumber:', inRaffleNumber.get(),
      ' buttonDisabled:', buttonDisabled,
      ' latest:', latest ),
      console.log('----------------------------------------'),
      SoundCheck(),
    ]
  });

  return (
    <>
      <div>
        <div id='target' />
          <button disabled={buttonDisabled} onClick={() => handleRouletteStart()}>
            ルーレットスタート
          </button>
        </div>
      <motion.h1 className='container' animate={controls}>
        { inRaffleFloorNumber }
      </motion.h1>
    </>
  )
}
export default ActA
