import { NavLink } from 'react-router-dom'
import { useContext, useState } from "react";

import styled from "@emotion/styled";
import NavbarContext from '../../contexts/NavbarContext';

const StyledButton = styled.button`
  color: ${({ activeHome }) => activeHome ? '#ff99ff' : 'lightgray'};
  background: ${({ activeHome }) => activeHome ? 'linear-gradient(180deg, #660033, #9900cc)' : 'linear-gradient(180deg, #100c08, #660033)'};
  ${({ activeHome }) => activeHome && `text-shadow: lightgray 5px 5px 10px; box-shadow: #ff99ff 5px 5px 10px;`}
`;

const HomeNavbar = () => {

  const { navsHome, navsJHome, activeHome, setActiveHome, } = useContext(NavbarContext);
  // const { navs, navsJ, active, setActive, } = useContext(NavbarContext);

  return (
    <>
      {navsHome.map((nav, index) => {
      // {navs.map((nav, index) => {
        return (
          <span key={index}>
            <NavLink to={nav}>
              <StyledButton key={nav}
                activeHome={activeHome === nav}
                onClick={() => setActiveHome(nav)}
              >{navsJHome[index]}</StyledButton>
            </NavLink>
            {/* <NavLink to={nav}>
              <StyledButton key={nav}
                active={active === nav}
                onClick={() => setActive(nav)}
              >{navsJ[index]}</StyledButton>
            </NavLink> */}
          </span>
        )}
      )}
    </>
  )
}
export default HomeNavbar

