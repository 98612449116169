import { createContext, useState } from "react";

const NavbarContext = createContext();

export const NavbarContextProvider = ({ children }) => {
  const navs = ["Home", "Roulette", "MyPage", "About", "Parent"];
  const navsJ = ["ホーム", "ルーレット", "マイページ", "about", "Parent"];
  const [active, setActive] = useState(navs[0]);

  const navsHome = ["/Home", "/Home/Abc", "/Home/Def"];
  const navsJHome = ["ホーム", "Abc", "Def"];
  const [activeHome, setActiveHome] = useState(navsHome[0]);

  const navsAbout = ["/About", "/About/Abc", "/About/Def"];
  const navsJAbout = ["about", "Abc", "Def"];
  const [activeAbout, setActiveAbout] = useState(navsAbout[0]);

  // const navsRoulette = ["/Roulette", "/Roulette/Abc", "/Roulette/Def"];
  // const navsJRoulette = ["ルーレット", "Abc", "Def"];
  const navsRoulette = [
    "/Roulette",
    "/Roulette/Abc",
    "/Roulette/Def",
    "/Roulette/Spin",
    "/Roulette/ActA",
    "/Roulette/ActB",
    "/Roulette/ActC",
    "/Roulette/ActD",
    "/Roulette/ActE",
    "/Roulette/ActF",
    "/Roulette/ActG",
  ];
  const navsJRoulette = [
    "ルーレット",
    "Abc",
    "Def",
    "Spin",
    "ActA",
    "ActB",
    "ActC",
    "ActD",
    "ActE",
    "ActF",
    "ActG",
  ];
  const [activeRoulette, setActiveRoulette] = useState(navsRoulette[0]);

  const navsParent = ["/Parent", "/Parent/Abc", "/Parent/Def"];
  const navsJParent = ["ピアレント", "Abc", "Def"];
  const [activeParent, setActiveParent] = useState(navsParent[0]);

  return (
    <NavbarContext.Provider
      value={{
        navs,
        navsJ,
        active,
        setActive,
        navsHome,
        navsJHome,
        activeHome,
        setActiveHome,
        navsAbout,
        navsJAbout,
        activeAbout,
        setActiveAbout,
        navsRoulette,
        navsJRoulette,
        activeRoulette,
        setActiveRoulette,
        navsParent,
        navsJParent,
        activeParent,
        setActiveParent,
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
};

export default NavbarContext;
