import { motion, useMotionValue, useMotionValueEvent, useTransform, useAnimate, } from "framer-motion";
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

const Root = () => {
  const [ scope, animate ] = useAnimate()
  const [ selectedNumber, setSelectedNumber ] = useState(0);

  const count = useMotionValue(0);
  const rounded = useTransform(count, Math.round, { cramp: false });

  const handleRouletteStart = async() => {

    // alert('ボタン押下');

    // ボタンを押された感じ
    animate([
      ["button", {scale: 0.8}, {duration: 0.1, at: "<"}],
      ["button", {scale: 1}, {duration: 0.1}],
    ])

    // 0からスタート
    count.set(0);
    // count.jump(0);

    // 1～Xの数字をランダムでget
    // setSelectedNumber(randomNumberInRange(1, 100));
    setSelectedNumber(randomNumberInRange(1, 3));
  }

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  
  useEffect(() => {
    console.log('selectedNumber:', selectedNumber, ' count:', count.get(),);

    // const animation = animate(count, selectedNumber, {
    //   duration: 3,
    //   // 途中経過をログ出力
    //   onUpdate: latest => console.log(' selectedNumber:', selectedNumber, ' count:', count.get(), ),
    // });
    // return animation.stop;

    animate(count, selectedNumber, {
      duration: 3,
      // 途中経過をログ出力
      onUpdate: latest => console.log(' selectedNumber:', selectedNumber, ' count:', count.get(), ),
    });

  }, [selectedNumber]);
  // }, [selectedNumber, animate, count]);

  return (
    <>
      <motion.div className='home container'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2, duration: 1.5 }}
      >
        <h2>サンプルアニメホーム</h2>
        <Link to="/Roulette">
          <motion.button
            whileHover={{
              scale: 1.1,
              textShadow: "0px 0px 8px rgb(255, 255, 255)",
              boxShadow: "0px 0px 8px rgb(255, 255, 255)",
            }}
            >
            ルーレット
          </motion.button>
        </Link>
      </motion.div>
      <div ref={scope}>
        <div id='target' />
          <button className="mt-4 rounded-md rouletteStartButton px-4 py-2" onClick={() => handleRouletteStart()}>
            ルーレットスタート
          </button>
        </div>
      <div className="square">
          { selectedNumber }
      </div>
      <motion.h1 className='home container'>
          { rounded }
      </motion.h1>
      <div>前回と同じ番号の場合、数字が回らないバージョン</div>
    </>    
  )
}
export default Root