import "./Spin.css";
import { motion, useAnimationControls, animate, useMotionValue, useTransform, } from "framer-motion";
import { useEffect, useState, useContext } from "react";
import useSound from "use-sound";
import AppContext from "../../contexts/AppContext"

import { Box, Slider, Typography, Switch, Input, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";

import inRaffleMp3 from './sounds/inRaffle.mp3';
import stopMp3 from './sounds/stop.mp3';

import styled from "@emotion/styled";

const StyledButton = styled.button`
  width: 150px;
  color: ${({ disabled }) => disabled ? '#ffffff' : '#cccccc'};
  background: ${({ disabled }) => disabled ? 'linear-gradient(180deg, #666666, #999999)' : 'linear-gradient(180deg, #100c08, #000099)'};
  ${({ disabled }) => disabled && `text-shadow: #cccccc 5px 5px 10px; box-shadow: #cccccc 5px 5px 10px;`}
`;

function valueLabelFormat(value) {
  return value;
}

function calculateValue(value) {
  return value;
}

const Spin = () => {

  const { cookies, setCookie, removeCookie } = useContext(AppContext);
  // cookieの有効期限を365日に設定
  const expires = new Date(Date.now() + 365*24*60*60*1000 );

  const rouletteText = [
    "123",
    "456",
    // cookies.item01,
    // cookies.item02,
    // cookies.item03,
    // cookies.item04,
    // cookies.item05,
    // cookies.item06,
    // cookies.item07,
    // cookies.item08,
    // cookies.item09,
    // cookies.item10,
  ]
  
  // const rouletteText = (maxNumber) => {
  //   for( let i=0; i < maxNumber; i++ ){
  //     rouletteText[i] = cookies.item0 && i;
  //   }
  // }

  // for(let i=0; i<maxNumber; i++){
  //   rouletteText.item0 && {i};
      
  // }

  // const [rouletteSound, setRouletteSound] = useState(cookies.rouletteSound);
  const [rouletteSound, setRouletteSound] = useState();

  const handleChangeRouletteSound = (event) => {
    setRouletteSound(event.target.checked);
    setCookie('rouletteSound', event.target.checked, { expires: expires })
    // setCookie('rouletteSound', cookies.rouletteSound ? cookies.rouletteSound : false, { expires: extendedExpires });

  };

  // 1からmaxNumberまでの数字を抽選
  // const [ maxNumber, setMaxNumber ] = useState(cookies.maxNumber ? cookies.maxNumber : 10);
  const [ maxNumber, setMaxNumber ] = useState(20);

  const { gHistoriesSelectedNumberArrayState, setGHistoriesSelectedNumberArrayState } = useContext(AppContext);
  const { gHistoriesMaxNumberArrayState, setGHistoriesMaxNumberArrayState } = useContext(AppContext);

  const [playInRaffleSound] = useSound(inRaffleMp3);
  const [playStopSound] = useSound(stopMp3);

  // [1]の位置を上の場合0、右の場合1
  // const [ startPosition, setStartPosition ] = useState(0);
  const [ startPosition, setStartPosition ] = useState(1);
  // const [ startPosition, setStartPosition ] = useState(cookies.startPosition ? cookies.startPosition : 'up');

  // 矢印の色
  const [ arrowColor, setArrowColor ] = useState("#ff0");

  // マークのAnimationControls
  const markAnimationControls = useAnimationControls();

  // 中央数字のAnimationControls
  const centralNumberAnimationControls = useAnimationControls();

  // 数字の周囲を回るボールのAnimationControls
  const ballAnimationControls = useAnimationControls();

  // 矢印のAnimationControls
  const arrowAnimationControls = useAnimationControls();

  // 矢印のAnimationControls
  const changeSelectedItemAnimationControls = useAnimationControls();

  // ルーレット回転数
  let rouletteRoundCounter = 3;
  // 止まるまでの時間
  // let stopTimer = 2;
  let stopTimer = 0;

  // selectedNumberState：回転数を含めた抽選番号
  // 例えば1～100（maxNumber）の抽選で、ルーレット回転数が2の場合、201～300の整数値が抽選される
  const [ selectedNumberState, setSelectedNumberState ] = useState(0);

  // displayNumberState：画面表示用の回転数を含まない抽選番号
  // 値はanimate内で導出、更新される
  const [ displaySelectedNumberState, setDisplaySelectedNumberState ] = useState(0);

  // nowInRaffleState：抽選中の場合、trueにしてボタンを無効に設定
  const [ nowInRaffleState, setNowInRaffleState ] = useState(false);

  // nowInRaffleMotionValue：animateの中で使用し、小数を含めてselectedNumberStateの数字へ徐々に近づく値を設定
  const nowInRaffleMotionValue = useMotionValue(0);

  // intNowInRaffleTransform：animateの中で使用し、nowInRaffleMotionValueの変化がある度に表示する値に更新
  const intNowInRaffleTransform = useTransform(nowInRaffleMotionValue, Math.round);

  // randomNumberInRange(min, max)の引数で指定された整数の最小値、最大値に含まれる整数をランダムで選定
  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const handleRouletteStart = async() => {

    // 抽選中のため、ボタンを無効にする
    setNowInRaffleState(true);

    // 矢印の色を抽選時の色に変更
    setArrowColor("#ff0");

    // 前回止まった位置をスタート位置とする
    await nowInRaffleMotionValue.set(selectedNumberState % maxNumber + 1);
    setSelectedNumberState(nowInRaffleState);
    setDisplaySelectedNumberState(nowInRaffleState);

    // 1～Xの数字をランダムでget
    // 「maxNumber * rouletteRoundCounter + 1」は数字がrouletteRoundCounter周後に止まる
    // setSelectedNumberState(randomNumberInRange(201, 300));
    setSelectedNumberState(randomNumberInRange(maxNumber * rouletteRoundCounter + 1, maxNumber * (rouletteRoundCounter + 1)));
  }

  const addGHistoriesSelectedNumberArrayState = (displaySelectedNumberState) => {
    setGHistoriesSelectedNumberArrayState([...gHistoriesSelectedNumberArrayState, displaySelectedNumberState]);
  }

  const addGHistoriesMaxNumberArrayState = (displayMaxNumberState) => {
    setGHistoriesMaxNumberArrayState([...gHistoriesMaxNumberArrayState, displayMaxNumberState]);
  }

  animate(nowInRaffleMotionValue, selectedNumberState, {
    duration: stopTimer,
    onUpdate: latest => {
      // console.log(
      //   ' selectedNumberState:', selectedNumberState, ' intNowInRaffleTransform:', intNowInRaffleTransform.get(), ' nowInRaffleMotionValue:', nowInRaffleMotionValue.get(),
      //   ' nowInRaffleState:', nowInRaffleState,
      //   ' latest:', latest );

      // 例えば1～100（maxNumber）の抽選中で、intNowInRaffleTransform.get()が155の場合、
      // 155を100（maxNumber）で割った剰余55（0～99）に1を加えて56として表示（1～100）
      setDisplaySelectedNumberState(intNowInRaffleTransform.get() % maxNumber + 1);
    }
  });

  // 矢印マークのx軸及びy軸を三角関数より導出（矢印マークの中心点からの距離をdistanceFromCenterPointで設定
  const distanceFromCenterPoint = 120;

  // startPositionが1の時は、arrowX、arrowYに90°分足す
  const arrowX = (intNowInRaffleNumber) => {
    // if (cookies.directionItIsTurning === 'clockwise'){
    if (true){
      // return Math.sin(intNowInRaffleNumber / maxNumber * 2 * Math.PI + startPosition * Math.PI / 2) * distanceFromCenterPoint
      return Math.cos(intNowInRaffleNumber / maxNumber * 2 * Math.PI) * distanceFromCenterPoint
    // } else if (cookies.directionItIsTurning === 'counter-clockwise'){
    } else if (true){
      // return -Math.cos(intNowInRaffleNumber / maxNumber * 2 * Math.PI + startPosition * Math.PI / 2) * distanceFromCenterPoint
      return Math.cos(intNowInRaffleNumber / maxNumber * 2 * Math.PI) * distanceFromCenterPoint
    } else {
      return Math.cos(intNowInRaffleNumber / maxNumber * 2 * Math.PI) * distanceFromCenterPoint
    }
  }
  const arrowY = (intNowInRaffleNumber) => {
    // if (cookies.directionItIsTurning === 'clockwise'){
    if (true){
      // return -Math.cos(intNowInRaffleNumber / maxNumber * 2 * Math.PI + startPosition * Math.PI / 2) * distanceFromCenterPoint
      return Math.sin(intNowInRaffleNumber / maxNumber * 2 * Math.PI) * distanceFromCenterPoint
    // } else if (cookies.directionItIsTurning === 'counter-clockwise'){
    } else if (true){
      return -Math.sin(intNowInRaffleNumber / maxNumber * 2 * Math.PI) * distanceFromCenterPoint
      // return Math.sin(intNowInRaffleNumber / maxNumber * 2 * Math.PI + startPosition * Math.PI / 2) * distanceFromCenterPoint
    } else {
      return Math.sin(intNowInRaffleNumber / maxNumber * 2 * Math.PI) * distanceFromCenterPoint
    }
  }

  // 矢の回転角度
  // startPositionが1の時は、arrowX、arrowYに90°分足す
  let arrowDegree = (intNowInRaffleNumber) => {
    // return  intNowInRaffleNumber / maxNumber * 360 + startPosition * 90;
    // if (cookies.directionItIsTurning === 'clockwise'){
    if (true){
      return  intNowInRaffleNumber / maxNumber * 360 + 180;
    // } else if (cookies.directionItIsTurning === 'counter-clockwise'){
    } else if (true){
      return  360 - intNowInRaffleNumber / maxNumber * 360 ;
    } else {
      return  intNowInRaffleNumber / maxNumber * 360 + 180;
    }
  }

  const handleListRadioSelected = (selected) => {
    console.log('list:', selected);
    setCookie('displayList', selected, { expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) })

    if (selected === 'allList'){

    } else if (selected === 'selectedList'){

    } else if (selected === 'nonList'){

    } else {
    }
  }

  const displayList = () => {
    // if(cookies.displayList === 'allList'){
    if(true){
      return (
        <>
          {/* 全リスト */}
          <div
          style={{padding: '10px', margin: '0 auto', width: '330px',
            background: 'linear-gradient(180deg, #000000, #000000', border: '1px solid #cccccc', color: '#cccccc',
            fontFamily: '"Noto Sans Math", sans-serif', fontWeight: 500, textAlign: 'left' }}>
            <div>
              {rouletteText.map((item, index) => {
                console.log('index:', index, ' displaySelectedNumberState:', displaySelectedNumberState)
                if(index < maxNumber) {
                  // 一致している項目は、緑か黄色で表示
                  if(index === displaySelectedNumberState - 1){
                    // 停止数字だった場合は緑、そうでなければ黄色
                    if(selectedNumberState === intNowInRaffleTransform.get() && selectedNumberState !== 0) {
                      return <div key={index} style={{ backgroundColor: '#66ff33', color: '#000000', }}>{index+1}:{item}</div>;
                    } else {
                      return <div key={index} style={{ backgroundColor: '#ffff00', color: '#000000', }}>{index+1}:{item}</div>;
                    }
                  } else {
                    return <div key={index}>{index+1}:{item}</div>;
                  }
                }
              })}
            </div>
          </div>
        </>
      )
    }
    else if(cookies.displayList === 'selectedList'){
      return (
        <>
          {/* 選択値 */}
          <div
            style={{padding: '10px', margin: '0 auto', width: '330px',
            color: '#cccccc',
            background: 'linear-gradient(180deg, #000000, #000000', border: '1px solid #cccccc',
            fontFamily: '"Noto Sans Math", sans-serif', fontWeight: 500, textAlign: 'left' }}>
            <div>
              {displaySelectedNumberState}:{rouletteText[displaySelectedNumberState - 1]}
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="RouletteListBlock">
          </div>
        </>
      )
    }
  }

  useEffect(() => {
    // マークのanimation
    markAnimationControls.start({
      x: 155,
      y: 0,
      transition: {
        duration: 0,
      }
    });

    // // マークのanimation
    // markAnimationControls.start({
    //   x: 0,
    //   y: -155,
    //   transition: {
    //     duration: 0,
    //   }
    // });

    // 中央数字のanimation
    centralNumberAnimationControls.start({
      border: '5px solid #ffff00',
      background: ['linear-gradient(180deg, #000000, #000)'],
      color: '#ffff00',
      transition: {
        duration: 0,
      }
    });

    // ボールのanimation
    ballAnimationControls.start({
      border: '5px solid #ffff00',
      background: ['linear-gradient(180deg, #000, #000)'],
      x: arrowX(intNowInRaffleTransform.get()),
      y: arrowY(intNowInRaffleTransform.get()),
      transition: {
        // durationを0にしないと、最初は点の移動が速すぎて移動しきれず、中央に集まってしまう
        duration: 0,
      }
    });

    // 矢印のanimation
    arrowAnimationControls.start({
      x: arrowX(intNowInRaffleTransform.get()),
      y: arrowY(intNowInRaffleTransform.get()),
      transition: {
        duration: 0,
      }
    });

    // 矢印のanimation
    setArrowColor('#ff0');

    // ルーレットの位置情報をコンソール出力
    console.log(' int:', intNowInRaffleTransform.get() % maxNumber, ' x:', arrowX(intNowInRaffleTransform.get()), ' y:', arrowY(intNowInRaffleTransform.get()));

    // 数字が変わるタイミングで音出力
    if(selectedNumberState !== 0) {
      rouletteSound && playInRaffleSound();
    }

    // 数字が変わるタイミングでログ出力
    console.log(
      ' intNowInRaffle:', intNowInRaffleTransform.get(),
      ' displaySelectedNumber:', displaySelectedNumberState,
    );

    // 抽選数字になれば、決定音を鳴らして背景色を変更する
    if(selectedNumberState === intNowInRaffleTransform.get() && selectedNumberState !== 0) {
      rouletteSound && playStopSound();
      setArrowColor("#66ff33");

      changeSelectedItemAnimationControls.start({
        color: ['#66ff33'],
      });
    
      centralNumberAnimationControls.start({
        border: ['5px solid #66ff33'],
        background: ['linear-gradient(180deg, #000000, #000066)'],
        color: '#66ff33',
        transition: {
          duration: 0, 
        }
      });

      ballAnimationControls.start({
        border: '5px solid #66ff33',
        background: ['linear-gradient(180deg, #000000, #000000)'],
      });

      // 抽選が終ったため、ボタンを有効にする
      setNowInRaffleState(false);

      // // 履歴表示
      // addGHistoriesSelectedNumberArrayState(displaySelectedNumberState);
      // addGHistoriesMaxNumberArrayState(maxNumber);
    }
  }, [displaySelectedNumberState]);

  return (
    <>
      <div className="Canvas">
        <div className="CanvasTitle">
          {/* {cookies.title} */}
          タイトル
        </div>

        {/* 項目表示 */}
        {displayList()}

        <div className="CanvasRouletteFrame">
          <motion.div className="Circumference"
            animate={centralNumberAnimationControls}
            // returnの処理内に関数を埋め込んでuseAnimationControls HooksでanimationCompleteで処理をする場合
            onAnimationComplete={
              async definition => {
                console.log('数値更新完了：', definition);
              }
            }
          >
            <motion.div className="Mark" animate={markAnimationControls} >
            <motion.svg className="arrow"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100" width="30" height="30"
            >
              {/* マーク */}
              <motion.path
                // 右マーク
                d="M 90,0 L 10, 50 L 90 100 z"
                // 上マーク
                // d="M 10, 10 L 90, 10 L 50 90 z"
                fill={arrowColor}
              />
            </motion.svg>
            </motion.div>
            <motion.div className="InnerCircumference"
              animate={centralNumberAnimationControls}
              // returnの処理内に関数を埋め込んでuseAnimationControls HooksでanimationCompleteで処理をする場合
              onAnimationComplete={
                async definition => {
                  console.log('数値更新完了：', definition);
                }
              }
            >
            <motion.div className="ball" animate={ballAnimationControls} />
            <motion.svg className="arrow"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100" width="30" height="30"
              animate={arrowAnimationControls}
            >
              {/* 矢印先端部 */}
              <motion.path
                d="M 50,0 L 90, 60 L 10 60 z"
                fill={arrowColor}
                transform={`rotate(${arrowDegree(displaySelectedNumberState-1)}, 50, 50)`}
              />
              {/* 矢印根本部 */}
              <motion.path
                d="M 50,10 L 70, 100 L 30 100 z"
                fill={arrowColor}
                transform={`rotate(${arrowDegree(displaySelectedNumberState-1)}, 50, 50)`}
              />
            </motion.svg>
              { displaySelectedNumberState }
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div>
        <StyledButton disabled={nowInRaffleState} onClick={() => handleRouletteStart()}>
          抽選
        </StyledButton>
      </div>

      <div className="controlPanel">
        <span className="controlPanelTitle">サウンド</span>
        <Switch className="rouletteSoundSwitch"
          checked={rouletteSound}
          onChange={handleChangeRouletteSound}
        />
        <Box className="FormBox">
          <FormControl>
            <FormLabel>項目表示</FormLabel>
            <RadioGroup
              row
              aria-labelledby="displayList-group-label"
              name="displayList-group"
            >
              {/* <FormControlLabel value='allList' control={<Radio />} checked={cookies.displayList === 'allList'} label="全部" onClick={(e) => {handleListRadioSelected(e.target.value)}} /> */}
              <FormControlLabel value='allList' control={<Radio />} label="全部" onClick={(e) => {handleListRadioSelected(e.target.value)}} />
              {/* <FormControlLabel value='selectedList' control={<Radio />} checked={cookies.displayList === 'selectedList'} label="あり" onClick={(e) => {handleListRadioSelected(e.target.value)}} /> */}
              <FormControlLabel value='selectedList' control={<Radio />} label="あり" onClick={(e) => {handleListRadioSelected(e.target.value)}} />
              {/* <FormControlLabel value='nonList' control={<Radio />} checked={cookies.displayList === 'nonList'} label="なし" onClick={(e) => {handleListRadioSelected(e.target.value)}} /> */}
              <FormControlLabel value='nonList' control={<Radio />} label="なし" onClick={(e) => {handleListRadioSelected(e.target.value)}} />
            </RadioGroup>
          </FormControl>
        </Box>
      </div>
    </>
  )
}
export default Spin
