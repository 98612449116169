import { NavLink } from 'react-router-dom'
import { useContext, useState } from "react";

import styled from "@emotion/styled";
import NavbarContext from '../contexts/NavbarContext';

const StyledButton = styled.button`
  width: 100px;
  color: ${({ active }) => active ? '#66ffff' : 'lightgray'};
  background: ${({ active }) => active ? 'linear-gradient(180deg, #000099, #0033ff)' : 'linear-gradient(180deg, #100c08, #000099)'};
  ${({ active }) => active && `text-shadow: lightgray 5px 5px 10px; box-shadow: #66ffff 5px 5px 10px;`}
`;

// const StyledButton = styled.button`
//   color: ${({ active }) => active ? '#ff99ff' : 'lightgray'};
//   background: ${({ active }) => active ? 'linear-gradient(180deg, #660033, #9900cc)' : 'linear-gradient(180deg, #100c08, #660033)'};
//   ${({ active }) => active && `text-shadow: lightgray 5px 5px 10px; box-shadow: #ff99ff 5px 5px 10px;`}
// `;

const Navbar = () => {

  const { navs, navsJ, active, setActive, } = useContext(NavbarContext);

  return (
    <>
      {navs.map((nav, index) => {
        return (
          <span key={index}>
            <NavLink to={nav}>
              <StyledButton key={nav}
                active={active === nav}
                onClick={() => setActive(nav)}
              >{navsJ[index]}</StyledButton>
            </NavLink>
          </span>
        )}
      )}
    </>
  )
}
export default Navbar
